import { useEffect } from 'react';

import { UIProvider } from '@compono/ui';
import { theme } from '@compono/ui-theme-compono';
import { useAuth0 } from '@auth0/auth0-react';
import { Global } from '@emotion/react';
import { BrowserRouter } from 'react-router-dom';
import { AnalyticsProvider, createPendo } from '@compono/analytics';
import { ErrorBoundary } from './features/pages';

import Router from './Router';
import { Auth0Wrapper, ApolloProviderWithAuth0 } from './auth0';
import { PENDO_KEY, ENVIRONMENT } from './lib/env';

const App = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  return (
    <AnalyticsProvider
      appName="dataview-insights "
      plugins={[
        createPendo({
          token: PENDO_KEY,
          stage: ENVIRONMENT,
        }),
      ]}
    >
      <UIProvider theme={theme}>
        <Global
          styles={{
            'html, body': { margin: 0, padding: 0 },
            body: { height: '100vh', width: '100vw' },
            '#app': { width: '100%', height: '100%' },
            '*': { boxSizing: 'border-box' },
          }}
        />
        <Auth0Wrapper>
          <ApolloProviderWithAuth0>
            <ErrorBoundary>
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </ErrorBoundary>
          </ApolloProviderWithAuth0>
        </Auth0Wrapper>
      </UIProvider>
    </AnalyticsProvider>
  );
};

export default App;
