import { createRoot } from 'react-dom/client';
import { ga, gtm, mixpanel } from '@shortlyster/analytics-kit';
import * as intercom from '@shortlyster/intercom-kit';
import { initSentry } from '@shortlyster/sentry-browser';

import {
  INTERCOM_TOKEN,
  MIXPANEL_TOKEN,
  IS_PRODUCTION,
  HEADLESS_TESTING,
  ENVIRONMENT,
  RELEASE,
  SENTRY_DSN,
  CHMLN_TOKEN,
} from './lib/env';
import App from './App';

const rootEl = document.getElementById('app') as HTMLElement;
const root = createRoot(rootEl);

root.render(<App />);

const chmln = require('@chamaeleonidae/chmln');

// Remove this line to test intercom and mixpanel
if (process.env.NODE_ENV === 'production' && !HEADLESS_TESTING) {
  if (IS_PRODUCTION) {
    ga.init();
    gtm.init();
  }
  mixpanel.init(MIXPANEL_TOKEN);
  intercom.init(INTERCOM_TOKEN);

  initSentry({ ENVIRONMENT, RELEASE, SENTRY_DSN });
  chmln.init(CHMLN_TOKEN);
  mixpanel.configure({ trackPageViews: true });
}
