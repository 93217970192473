// DO NOT UNDO DESTRUCTURING. babel environment plugin is DUMB AS ROCKS
/* eslint-disable import/no-mutable-exports */
/* eslint-disable prefer-destructuring */
let API_URL = process.env.API_URL || 'http://localhost:3000/dev';
const AUTH0_CLIENT_ID = process.env.AUTH0_CLIENT_ID;
const AUTH0_DOMAIN = process.env.AUTH0_DOMAIN;
const AUTH0_ENV_NAME = process.env.AUTH0_ENV_NAME;
const ENVIRONMENT = process.env.ENVIRONMENT || 'development';
const RELEASE = process.env.RELEASE || 'development';

const MIXPANEL_TEST_TOKEN = 'cd090c9a3b2bbc65ca3beaf9ce5741ce';
const MIXPANEL_PROD_TOKEN = 'fdfb701ade5d931d1894a69f75e7c085';
const INTERCOM_PROD_TOKEN = 'wetp3ktn';
const INTERCOM_TEST_TOKEN = 'ewnichpq'; // Shortlyster [TEST]
/* eslint-enable import/no-mutable-exports */
/* eslint-enable prefer-destructuring */

const {
  location: { hostname },
} = document;

// when running in acceptar and need to change localhost -> dockerhost
if (API_URL && API_URL.endsWith('localhost:3000/dev') && !API_URL.includes(hostname)) {
  API_URL = API_URL.replace('localhost:', `${hostname}:`);
}

const HEADLESS_TESTING = navigator.userAgent.includes(' HeadlessChrome/');

const CHMLN_TOKEN = 'Shbz4KjNOf9j6578Nw5kBzUFc9MWKeOjdqUrEDvwQriL5d-1MnsMi-D7YJoVxdGB7MsLkg';
const SENTRY_DSN = 'https://62d2fea48e654dc2abeb050793d44f0b@o360657.ingest.sentry.io/3677530';

const PROD_HOSTNAME = /^\w*\.shortlyster\.com$/;
const IS_PRODUCTION = PROD_HOSTNAME.test(window.location.hostname);

const INTERCOM_TOKEN = IS_PRODUCTION ? INTERCOM_PROD_TOKEN : INTERCOM_TEST_TOKEN;
const MIXPANEL_TOKEN = IS_PRODUCTION ? MIXPANEL_PROD_TOKEN : MIXPANEL_TEST_TOKEN;
const PENDO_KEY = "e19329ab-6125-4bcb-7a5c-cd5d663288e7"

export {
  API_URL,
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH0_ENV_NAME,
  IS_PRODUCTION,
  INTERCOM_TOKEN,
  MIXPANEL_TOKEN,
  HEADLESS_TESTING,
  ENVIRONMENT,
  SENTRY_DSN,
  RELEASE,
  CHMLN_TOKEN,
  PENDO_KEY
};
