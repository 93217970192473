import styled from '@emotion/styled';
import { ApolloError } from '@apollo/client';

import { Link } from '@compono/ui';

const Centred = styled.div`
  margin: 2rem 0;
  text-align: center;
`;

const ErrorStack = styled.pre`
  text-align: left;
  padding: 1rem;
  border: 1px solid --colors-primary-100;
  background: --colors-black-1;
  white-space: pre-wrap;
  margin: 2em 0;
`;

type Props = {
  error?: ApolloError;
  reset: () => void;
  message?: string;
};

const message = (err?: ApolloError) => {
  switch (err?.graphQLErrors[0]?.message) {
    case 'Not a team member':
      return 'Not a team member - you must be added to the team to view this page.';

    default:
      return 'You do not have access to this page.';
  }
};

const AccessDenied = ({ error, reset }: Props) => (
  <Centred>
    <h1>Access Denied!</h1>
    <p>{message(error)}</p>
    {error && process.env.NODE_ENV === 'development' ? (
      <ErrorStack>{error.stack}</ErrorStack>
    ) : null}
    <Link href="/" onClick={() => reset()}>
      Return to dashboard
    </Link>
  </Centred>
);

export default AccessDenied;
