import { Component } from 'react';
import { Sentry } from '@shortlyster/sentry-browser';

import { NotFound } from 'lib/errors';

import FailWhalePage from './FailWhale';
import GqlErrorPage from './GqlError';
import NotFoundPage from './NotFound';

const hasGqlError = ({ graphQLErrors }: any = {}) => graphQLErrors && graphQLErrors.length;

type State = {
  error?: Error;
};

class ErrorBoundary extends Component<any, State> {
  state = { error: undefined };

  componentDidCatch(error: Error) {
    Sentry.captureException(error);
    this.setState({ error });
  }

  resetError = () => {
    this.setState({ error: undefined });
  };

  render() {
    const { error } = this.state;

    if (!error) return this.props.children;

    const NotFoundOrUnexpected = (error as any) instanceof NotFound ? NotFoundPage : FailWhalePage;
    const ErrorPage = hasGqlError(error) ? GqlErrorPage : NotFoundOrUnexpected;

    return <ErrorPage error={error} reset={this.resetError} />;
  }
}

export default ErrorBoundary;
