import { useEffect } from 'react';
import { Route, useLocation, Routes } from 'react-router-dom';
import loadable from '@loadable/component';
import { useAuth0 } from '@auth0/auth0-react';
import { useOrganisation } from '@shortlyster/auth-layer';
import { useAnalytics } from '@compono/analytics';

import { NotFoundPage } from './features/pages';
import Layout from './features/layout/Layout';
import Redirect from './lib/Redirect';

const JobsPage = loadable(() => import(/* webpackPrefetch: true */ './features/Jobs/JobsPage'));
const SourcingPage = loadable(
  () => import(/* webpackPrefetch: true */ './features/Sourcing/SourcingPage')
);
const ApplicationsPage = loadable(
  () => import(/* webpackPrefetch: true */ './features/Applications/ApplicationsPage')
);

function useScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
}

const Router = () => {
  const { user } = useAuth0();
  const [organisation] = useOrganisation();
  const { identify } = useAnalytics();

  // Pendo tracking
  useEffect(() => {
    if (user && organisation) {
      identify(user['https://shortlyster.com/app_metadata'].userId, {
        organisation: {
          id: organisation.id,
        },
      });
    }
  }, [user, organisation]);

  useScrollToTop();
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Redirect />} />
        <Route path="/organisation/:orgId" element={<Layout />}>
          <Route path="dashboard" element={<h1>Coming Soon</h1>} />
          <Route path="jobs" element={<JobsPage />} />
          <Route path="sourcing" element={<SourcingPage />} />
          <Route
            path="applications"
            element={
              window?.location?.href?.match(/insights\.compono\.com/) ? (
                <NotFoundPage reset={() => {}} />
              ) : (
                <ApplicationsPage />
              )
            }
          />
        </Route>
        <Route path="*" element={<NotFoundPage reset={() => {}} />} />
      </Route>
    </Routes>
  );
};

export default Router;
