import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const FeatureHider = ({ children }: Props) => {
  const location = window?.location?.href ?? 'nowhere';

  if (location.match(/insights\.compono\.com/)) return null;
  return <>{children}</>;
};

export default FeatureHider;
