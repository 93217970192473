import { MainNav, MainNavLink, MainNavButton, makeLinkComponent } from '@compono/ui';
import {
  LogoutSolidIcon,
  BriefcaseSolidIcon,
  ShareMegaphoneSolidIcon,
  ClipboardDocumentSolidIcon,
} from '@compono/ui-icons';
import {
  NavLink as ReactRouterNavLink,
  useLocation,
  createSearchParams,
  useSearchParams,
} from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useMemo, useEffect } from 'react';
import { subMonths } from 'date-fns';
import FeatureHider from '../various/FeatureHider';

function useQueryParams() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const Nav = () => {
  const { logout } = useAuth0();

  const NavLink = makeLinkComponent(({ href, children, ...restProps }, ref) => {
    const queryParams = useQueryParams();
    const fromDate = queryParams.get('fromDate');
    const toDate = queryParams.get('toDate');

    const isDateRangeTooLong =
      !!toDate &&
      !!fromDate &&
      new Date(toDate).getTime() - new Date(fromDate).getTime() > 92 * 1000 * 3600 * 24;

    const limitedFromDate = isDateRangeTooLong
      ? subMonths(new Date(toDate), 3).toISOString().slice(0, 10)
      : fromDate;

    const [_searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
      if (toDate && limitedFromDate && isDateRangeTooLong) {
        setSearchParams(createSearchParams({ fromDate: limitedFromDate, toDate }));
      }
    }, [limitedFromDate, toDate, fromDate]);

    const queryString = `?fromDate=${limitedFromDate}&toDate=${toDate}`;

    return (
      <ReactRouterNavLink
        ref={ref}
        to={href + (limitedFromDate && toDate ? queryString : '')}
        {...restProps}
      >
        {children}
      </ReactRouterNavLink>
    );
  });

  return (
    <MainNav navLinkComponent={NavLink}>
      <MainNavLink href="jobs" icon={BriefcaseSolidIcon} label="Jobs" />
      <FeatureHider>
        <MainNavLink href="applications" icon={ClipboardDocumentSolidIcon} label="Applications" />
      </FeatureHider>
      <MainNavLink href="sourcing" icon={ShareMegaphoneSolidIcon} label="Sourcing" />
      <MainNavButton
        label="Log out"
        icon={LogoutSolidIcon}
        onClick={() => logout({ returnTo: window.location.origin })}
      />
    </MainNav>
  );
};

export default Nav;
