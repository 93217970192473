import { ReactNode } from 'react';
import { Box } from '@compono/ui';

type Props = {
  children: ReactNode;
};

const WidthLimiter = ({ children }: Props) => (
  <Box>
    <Box sx={{ maxWidth: '1040px', margin: '0 auto' }}>{children}</Box>
  </Box>
);

export default WidthLimiter;
