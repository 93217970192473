import { useEffect, useState } from 'react';
import { ApolloProvider, ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import { API_URL } from 'src/lib/env';
import { useOrganisation } from '@shortlyster/auth-layer';

const ApolloProviderWithAuth0 = ({ children }: { children: any }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [client, setClient] = useState();
  const [organisation] = useOrganisation();

  const httpLink = new HttpLink({
    uri: `${API_URL}/graphql`,
  });

  const cache = new InMemoryCache({
    addTypename: false,
  });

  const handleSetClient = (organisationId?: string) => {
    const authLink = setContext(async (_, { headers, ...auth }) => {
      let token: string | undefined;
      const orgId =
        organisationId ||
        window.location.pathname.replace(/organisation\/(.*)\/.*/gi, '$1').slice(1);

      try {
        token = await getAccessTokenSilently();
      } catch (error) {
        console.log(error);
      }

      if (!token) return { headers, ...auth };

      return {
        ...auth,
        headers: {
          ...headers,
          ...(orgId && { orgId }),
          Authorization: `Bearer ${token}`,
        },
      };
    });

    setClient(
      new ApolloClient({
        link: authLink.concat(httpLink),
        cache,
      }) as any
    );
  };

  useEffect(() => {
    if (organisation?.id) {
      handleSetClient(organisation?.id);
    }
  }, [organisation]);

  if (!client) {
    handleSetClient();
  }
  return <ApolloProvider client={client as any}>{children}</ApolloProvider>;
};

export default ApolloProviderWithAuth0;
