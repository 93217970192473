import AccessDeniedPage from './AccessDenied';
import FailWhalePage from './FailWhale';
import NotFoundPage from './NotFound';

type Props = {
  error: any;
  reset: () => void;
};

// Pull first GraphQL error statusCode
const GqlError = (props: Props) => {
  switch (props.error.graphQLErrors[0].statusCode) {
    case 401:
    case 402:
    case 403:
      return <AccessDeniedPage {...props} />;

    case 404:
      return <NotFoundPage {...props} />;

    default:
      return <FailWhalePage {...props} />;
  }
};

export default GqlError;
