import { Component } from 'react';
import styled from '@emotion/styled';
import { Link } from '@compono/ui';
import BrowserTitle from 'features/various/BrowserTitle';

const Centred = styled.div`
  margin: 2rem 0;
  text-align: center;
`;

const ErrorStack = styled.pre`
  text-align: left;
  padding: 1rem;
  border: 1px solid --colors-primary-100;
  background: --colors-black-1;
  white-space: pre-wrap;
  margin: 2em 0;
`;

type Props = {
  error?: Error;
  reset: () => void;
};

export default class FailWhale extends Component<Props> {
  render() {
    const { error, reset } = this.props;

    return (
      <Centred>
        <BrowserTitle>Error</BrowserTitle>
        <h1>Oops!</h1>
        <p>Something quite unexpected happened...</p>
        <p>We're looking into it!</p>
        {error && process.env.NODE_ENV === 'development' ? (
          <ErrorStack>{error.stack}</ErrorStack>
        ) : null}
        <Link href="/" onClick={() => reset()}>
          Return to dashboard
        </Link>
      </Centred>
    );
  }
}
