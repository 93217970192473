import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useOrganisation } from '@shortlyster/auth-layer';

export type AuthResponse = {
  getPermissions: {
    permissions: string[];
    organisationId: string;
  };
};

const GetPermissions = gql`
  query GetPermissions {
    getPermissions {
      permissions
      organisationId
    }
  }
`;

const useOrgnanisationId = () => {
  const { data } = useQuery<AuthResponse>(GetPermissions);
  const [org, setOrg] = useOrganisation();

  useEffect(() => {
    if (data) {
      setOrg({ id: data.getPermissions.organisationId });
    }
  }, [data]);

  return org?.id;
};

export default useOrgnanisationId;
