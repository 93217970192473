import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import useOrgnanisationId from './useOrganisationId';

const Redirect = () => {
  const navigate = useNavigate();
  const orgId = useOrgnanisationId();

  useEffect(() => {
    // TODO remove the /jobs once we have deployed the dashboard
    if (orgId) navigate(`organisation/${orgId}/jobs`);
  }, [orgId]);

  return <></>;
};

export default Redirect;
