import { Outlet } from 'react-router';
import { GlobalLayout, Sidebar, MainContent, Loader } from '@compono/ui';
import { gql, useQuery } from '@apollo/client';

import Nav from './Nav';
import InputWrapper from './InputWrapper';
import WidthLimiter from './WidthLimiter';

export const getTeams = gql`
  query GetTeams {
    teamsList {
      name
      id
    }
  }
`;

const Layout = () => {
  const { data, loading, error } = useQuery(getTeams);

  if (error) throw error;

  return (
    <GlobalLayout>
      <Sidebar>
        <Nav />
      </Sidebar>
      <MainContent>
        <WidthLimiter>
          {loading ? (
            <Loader />
          ) : (
            <>
              <InputWrapper teams={data.teamsList} />
              <Outlet />
            </>
          )}
        </WidthLimiter>
      </MainContent>
    </GlobalLayout>
  );
};

export default Layout;
