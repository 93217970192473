type Props = {
  children: string;
};

const BrowserTitle = ({ children }: Props) => {
  if (typeof children === 'string') document.title = children;
  return null;
};

export default BrowserTitle;
