import { useEffect, FC } from 'react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_ENV_NAME } from 'src/lib/env';

export const AuthBouncer: FC = ({ children }) => {
  // state
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: window.location.origin,
        },
      });
    }
  }, [isLoading, isAuthenticated]);

  return <>{children}</>;
};

type Auth0WrapperProps = {
  children: any;
};

const Auth0Wrapper = ({ children }: Auth0WrapperProps) => (
  <Auth0Provider
    connection={`compono-connection-${decodeURIComponent(AUTH0_ENV_NAME as string).replace(
      /"/g,
      ''
    )}`}
    clientId={decodeURIComponent(AUTH0_CLIENT_ID as string).replace(/"/g, '')}
    redirectUri={window.location.origin}
    domain={decodeURIComponent(AUTH0_DOMAIN as string).replace(/"/g, '')}
    audience="compono-api"
    scope="read:all"
  >
    <AuthBouncer>{children}</AuthBouncer>
  </Auth0Provider>
);

export default Auth0Wrapper;
