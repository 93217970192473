import { useState, useEffect } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { subMonths } from 'date-fns';
import {
  DatePickerRangeStartInput,
  DatePickerRangeEndInput,
  DatePickerRange,
  FormField,
  Select,
  Inline,
  Box,
} from '@compono/ui';
import { useQueryParams } from '../various/useQueryParams';

type Team = {
  id: string;
  name: string;
};

export type Props = {
  teams?: Team[];
};

const InputWrapper = ({ teams = [] }: Props) => {
  const queryParams = useQueryParams();
  const defaultTeamId = queryParams.get('teamId') || 'all';
  const defaultFromDate =
    queryParams.get('fromDate') || subMonths(new Date(), 1).toISOString().slice(0, 10);
  const defaultToDate = queryParams.get('toDate') || new Date().toISOString().slice(0, 10);
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);
  const [isInvalidToInput, setIsInvalidToInput] = useState(false);
  const [isInvalidFromInput, setIsInvalidFromInput] = useState(false);
  const [teamId, setTeamId] = useState('all');
  const [_searchParams, setSearchParams] = useSearchParams();

  const start = new Date(fromDate);
  const end = new Date(toDate);
  const dateRange = end.getTime() - start.getTime();
  const minDate = new Date(2000, 1, 1);
  const isBelowMinDate = start < minDate || end < minDate;
  const isStartAfterEnd = start > end;
  const isDateRangeTooLong = dateRange > 92 * 1000 * 3600 * 24;
  const isInvalidInput = isInvalidToInput || isInvalidFromInput;
  const isInvalid = isBelowMinDate || isStartAfterEnd || isInvalidInput || isDateRangeTooLong;
  const isDefault =
    fromDate === defaultFromDate && toDate === defaultToDate && teamId === defaultTeamId;
  const dateError = isDateRangeTooLong
    ? 'Date range cannot be longer than 3 months'
    : 'Please select a valid date range';

  useEffect(() => {
    if (!isInvalid && !isDefault) {
      setSearchParams(createSearchParams({ fromDate, toDate, teamId }));
    }
  }, [fromDate, toDate, teamId]);

  const options = [
    { label: 'All teams', value: 'all' },
    ...teams
      .map(t => ({ label: t.name, value: t.id }))
      .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)),
  ];

  return (
    <Box as="article" sx={{ margin: 5 }}>
      <Inline>
        <Box sx={{ paddingRight: 3 }}>
          <Select
            label="Team selection"
            defaultValue="all"
            onChange={(selection: any) => setTeamId(selection?.target?.value)}
            value={teamId}
            size="sm"
          >
            {options.map(option => (
              <option key={`team-${option.value}`} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </Box>
        <Inline>
          <DatePickerRange>
            <FormField
              fieldId="fromDate"
              label="Start Date"
              info={'Start tracking statistics from this date.'}
              error={isInvalid ? dateError : ''}
            >
              <DatePickerRangeStartInput
                id="fromDate"
                name="fromDate"
                size="sm"
                value={fromDate}
                onChange={value => {
                  switch (true) {
                    case value === '':
                      setIsInvalidFromInput(false);
                      break;
                    case value === 'Invalid Date':
                      setIsInvalidFromInput(true);
                      break;
                    default:
                      setIsInvalidFromInput(false);
                      setFromDate(new Date(value).toISOString().slice(0, 10));
                  }
                }}
                invalid={isInvalid}
              />
            </FormField>
            <FormField
              fieldId="toDate"
              label="End Date"
              info={'Collect statistics up to this end date.'}
            >
              <DatePickerRangeEndInput
                size="sm"
                id="toDate"
                name="toDate"
                value={toDate}
                onChange={value => {
                  switch (true) {
                    case value === '':
                      setIsInvalidToInput(false);
                      break;
                    case value === 'Invalid Date':
                      setIsInvalidToInput(true);
                      break;
                    default:
                      setIsInvalidToInput(false);
                      setToDate(new Date(value).toISOString().slice(0, 10));
                  }
                }}
                invalid={isInvalid}
              />
            </FormField>
          </DatePickerRange>
        </Inline>
      </Inline>
    </Box>
  );
};

export default InputWrapper;
