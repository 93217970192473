import styled from '@emotion/styled';
import BrowserTitle from 'features/various/BrowserTitle';
import { Link } from '@compono/ui';

const Centred = styled.div`
  margin: 2rem 0;
  text-align: center;
`;

const ErrorStack = styled.pre`
  text-align: left;
  padding: 1rem;
  border: 1px solid --colors-primary-100;
  background: --colors-white-1;
  white-space: pre-wrap;
  margin: 2em 0;
`;

type Props = {
  error?: Error;
  reset: () => void;
};

const NotFound = ({ error, reset }: Props) => (
  <Centred>
    <BrowserTitle>Not found</BrowserTitle>
    <h1>Oops!</h1>
    <p>We can't seem to find the page you're looking for.</p>
    {error && <p>We're looking into it!</p>}
    {error && process.env.NODE_ENV === 'development' ? (
      <ErrorStack>{error.stack}</ErrorStack>
    ) : null}
    <Link href="/" onClick={() => reset()}>
      Return to dashboard
    </Link>
  </Centred>
);

export default NotFound;
